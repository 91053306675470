<script>
  import { onMount } from 'svelte';
  import { getAddress } from '@ethersproject/address';
  import { formatEther } from '@ethersproject/units';
  import { setDefaults as setToast, toast } from 'bulma-toast';

  let address = null;
  let faucetInfo = {
    account: '0x0000000000000000000000000000000000000000',
    network: 'Testnet',
    payout: 1,
  };

  $: document.title = `OHO Testnet ${faucetInfo.network} Faucet`;

  onMount(async () => {
    const res = await fetch('/api/info');
    faucetInfo = await res.json();
    faucetInfo.network = capitalize(faucetInfo.network);
    faucetInfo.payout = parseInt(formatEther(faucetInfo.payout));
  });

  setToast({
    position: 'bottom-center',
    dismissible: true,
    pauseOnHover: true,
    closeOnClick: false,
    animate: { in: 'fadeIn', out: 'fadeOut' },
  });

  async function handleRequest() {
    try {
      address = getAddress(address);
    } catch (error) {
      toast({ message: error.reason, type: 'is-warning' });
      return;
    }

    let formData = new FormData();
    formData.append('address', address);
    const res = await fetch('/api/claim', {
      method: 'POST',
      body: formData,
    });
    let message = await res.text();
    let type = res.ok ? 'is-success' : 'is-warning';
    toast({ message, type });
  }

  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }
</script>

<main>
  <section class="hero is-info is-fullheight">
        <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-6 is-offset-3">
	  <img src="/faucet.png" alt="Faucet" style="width: 140px; margin-bottom: 15px;"/>
          <h1 class="title">
            OHO Testnet Faucet
          </h1>
          <h2 class="subtitle" style="padding: 1rem 0; margin-bottom: 0px;">
             Requesting {faucetInfo.payout} OHO from {faucetInfo.account}
          </h2>
          <div class="box">
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input
                  bind:value={address}
                  class="input is-rounded"
                  type="text"
                  placeholder="Please enter your wallet address starting with 0x..."
                />
              </p>
              <p class="control">
                <button
                  on:click={handleRequest}
                  class="button is-primary is-rounded"
                >
                  Request
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<style>
  .hero.is-info {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
       no-rOHOat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .hero .subtitle {
    padding: 3rem 0;
    line-height: 1.5;
  }
  .box {
    border-radius: 19px;
  }
</style>
